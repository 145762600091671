import React from "react";
import styles from "./AuthBoxContent.module.css";
import shared from "../../styles/shared.module.css";
import StravaButton from "./StravaButton";
import classNames from "classnames";
import sharedStyles from "../../styles/shared.module.css";
import { Link } from "react-router-dom";
import GarminButton from "./GarminButton";
import { DOMAIN, usePublicGetCitiesQuery } from "../../state/api/ApiSlice";
import { getResultsFromResponse } from "../../data/ApiData";
import { GoogleLoginButton } from "react-social-login-buttons";

export default function AuthBoxContent() {
  const { data: citiesResponse, isLoading } = usePublicGetCitiesQuery({});
  const cities = getResultsFromResponse(citiesResponse);
  const count = cities.length;
  return (
    <>
      <div className={classNames(shared.heading2, styles.authHeader)}>
        Join for free!
      </div>
      <div className={styles.signIn}>
        or{" "}
        <Link className={shared.link} to={"/signin"}>
          sign in
        </Link>
      </div>
      <div className={styles.authMessageContainer}>
        <img
          src={require("../../assets/landing/exclaimation.png")}
          width={23}
          height={23}
          style={{ marginRight: 4 }}
          alt={"Exclamation point img"}
        />
        <div className={classNames(styles.authMessage, shared.heading4)}>
          We currently support{" "}
          <Link to={"/area-list"} className={styles.fullListLink}>
            {`${!isLoading ? count || 80 : "--"} cities and areas`}
          </Link>{" "}
          around the UK and the list is constantly growing
          <br />
          <br style={{ marginBottom: 5 }} />
        </div>
      </div>
      <div style={{ flex: 1 }} />
      <span
        className={classNames(styles.platformLoginMessage, shared.heading4)}
      >
        Login with Strava or Garmin* and all your activities will be
        automatically synced!
      </span>
      <div className={classNames(sharedStyles.heading5, styles.termsMessage)}>
        By signing up you are agreeing to the{" "}
        <Link className={styles.highlight} to={"/t_and_c"}>
          terms and conditions
        </Link>{" "}
        and{" "}
        <Link className={styles.highlight} to={"/privacy_policy"}>
          privacy policy
        </Link>
      </div>
      <StravaButton />
      <GarminButton style={{ marginTop: 10 }} />
      <hr />
      <div className={styles.googleButtonContainer}>
        <GoogleLoginButton
          style={{ width: 250 }}
          onClick={() =>
            (window.location.href = `${DOMAIN}/thirdparty/google/auth`)
          }
        />
      </div>
      <div className={classNames(styles.platformMessage2, shared.heading5)}>
        *Garmin only supports activities from the last 5 years, not all time. If
        you have both Garmin and Strava then we recommend using Strava.
      </div>
    </>
  );
}
