import React, { useEffect } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { User } from "framework";
import { setToken, setVendor } from "../../state/userslice/UserSlice";
import { useDispatch } from "react-redux";
export default function ThirdPartyAuthCallback() {
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const vendor = useParams<{ vendor: string }>().vendor;
  console.log(window.location.search, { token, vendor });
  const history = useHistory();

  useEffect(() => {
    if (token) {
      User.setToken(token as string);
      dispatch(setToken(token));
      dispatch(setVendor(vendor));
      history.push("/tabs/home");
    }
  }, [token]);

  return <div>loading auth...</div>;
}
