import React from "react";
import ActivityDescriptionSettings from "../../routes/settings/components/ActivityDescriptionSettings";
import {
  useGetSettingsQuery,
  useSetSettingsMutation,
} from "../../state/api/ApiSlice";
import { SetSettingsType } from "cp-server";
import { isLoggedInSelector } from "../../state/userslice/UserSlice";
import { getStore } from "../../state/Store";

export default function ActivityDescriptionPermissions() {
  const isLoggedIn = isLoggedInSelector(getStore().getState());
  const { data: settings } = useGetSettingsQuery(undefined, {
    skip: !isLoggedIn,
  });
  const [setSettings] = useSetSettingsMutation();
  const saveSettings = (settings: SetSettingsType) => {
    setSettings({ settings });
  };
  function renderSettings() {
    if (settings?.success) {
      return (
        <ActivityDescriptionSettings
          {...settings.settings}
          saveSettings={saveSettings}
          key={String(settings.settings)}
        />
      );
    }
  }
  return <div>{renderSettings()}</div>;
}
