import { Geolocation } from "@capacitor/geolocation";
import { Point } from "geojson";
import { isWeb } from "../../platform/PlatformManager";
import { registerPlugin } from "@capacitor/core";
import { BackgroundGeolocationPlugin } from "@capacitor-community/background-geolocation";
const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>(
  "BackgroundGeolocation"
);

// To start listening for changes in the device's location, add a new watcher.
// You do this by calling 'addWatcher' with an options object and a callback. A
// Promise is returned, which resolves to the callback ID used to remove the
// watcher in the future. The callback will be called every time a new location
// is available. Watchers can not be paused, only removed. Multiple watchers may
// exist simultaneously.

async function getLocation() {
  let position;
  if (isWeb()) {
    await new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition((pos) => {
        position = pos;
        resolve(pos);
      });
    });
  } else {
    await Geolocation.requestPermissions();

    position = await Geolocation.getCurrentPosition();
  }
  const point: Point = {
    type: "Point",
    coordinates: [position.coords.longitude, position.coords.latitude],
  };
  return point;
}

// async function listenForLocation(
//   listener: (position: [number, number]) => void
// ) {
//   console.log("SETUP{ LISTENER");
//   let testIndex = 0;
//   setInterval(() => {
//     const pos = testData[testIndex];
//     testIndex++;
//     console.log("test data", pos);
//     if (pos) {
//       listener(pos);
//     }
//   }, 2000);
//   return "";
//   // return await Geolocation.watchPosition(
//   //   { enableHighAccuracy: true, timeout: 500 },
//   //   (position, err) => {
//   //     if (position) {
//   //      const pos: [number, number] = [
//   //         position.coords.longitude,
//   //         position.coords.latitude,
//   //       ];
//   //      listener(pos);
//   //     }
//   //   }
//   // );
// }

async function removeLocationListener(watchId: string) {
  await Geolocation.clearWatch({ id: watchId });
}

async function removeBackgroundLocationWatcher(watcher_id: string) {
  BackgroundGeolocation.removeWatcher({
    id: watcher_id,
  });
}

// Coordinates are returned in the format [longitude, latitude]. This is consistent with the GeoJSON format.
async function startBackgroundLocationWatch(
  callback: (position: [number, number]) => void
) {
  const watcherId = await BackgroundGeolocation.addWatcher(
    {
      // If the "backgroundMessage" option is defined, the watcher will
      // provide location updates whether the app is in the background or the
      // foreground. If it is not defined, location updates are only
      // guaranteed in the foreground. This is true on both platforms.

      // On Android, a notification must be shown to continue receiving
      // location updates in the background. This option specifies the text of
      // that notification.
      backgroundMessage: "Cancel to prevent battery drain.",

      // The title of the notification mentioned above. Defaults to "Using
      // your location".
      backgroundTitle: "Tracking You.",

      // Whether permissions should be requested from the user automatically,
      // if they are not already granted. Defaults to "true".
      requestPermissions: true,

      // If "true", stale locations may be delivered while the device
      // obtains a GPS fix. You are responsible for checking the "time"
      // property. If "false", locations are guaranteed to be up to date.
      // Defaults to "false".
      stale: false,

      // The minimum number of metres between subsequent locations. Defaults
      // to 0.
      distanceFilter: 50,
    },
    function listenerCallback(location, error) {
      console.log({ location });
      if (error) {
        if (error.code === "NOT_AUTHORIZED") {
          if (
            window.confirm(
              "This app needs your location, " +
                "but does not have permission.\n\n" +
                "Open settings now?"
            )
          ) {
            // It can be useful to direct the user to their device's
            // settings when location permissions have been denied. The
            // plugin provides the 'openSettings' method to do exactly
            // this.
            // @ts-ignore
            BackgroundGeolocation.openSettings();
          }
        }
        return console.error(error);
      }
      if (location) {
        const { latitude, longitude } = location;
        callback([longitude, latitude]);
      }
      return console.log(location);
    }
  ).then(function after_the_watcher_has_been_added(watcher_id) {
    return watcher_id;
  });
  return watcherId;
}

export default {
  getLocation,
  removeLocationListener,
  removeBackgroundLocationWatcher,
  startBackgroundLocationWatch,
};
