import React, { Component, useEffect, useState } from "react";
import styles from "./FeedbackPopup.module.css";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Button,
  CircularProgress,
  Dialog,
  MenuItem,
  Select,
} from "@mui/material";
import { hidePopup, PopupTypes } from "../../state/popups/PopupsActions";
import { FeedbackPopupType } from "../../state/popups/PopupsTypes";
import { feedbackPopupSelector } from "../../state/popups/PopupsSelectors";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";

import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import { isLoggedInSelector } from "../../state/userslice/UserSlice";
import {
  useSubmitFeedbackMutation,
  useSubmitFeedbackPublicMutation,
} from "../../state/api/ApiSlice";

export enum FeedbackCategory {
  general = "general",
  city_request = "city_request",
  feature_request = "feature_request",
  criticism = "criticism",
  maps = "maps",
  activities = "activities",
  landmarks = "landmarks",
  new_features = "new_features",
}

const categories: { [key in keyof typeof FeedbackCategory]: string } = {
  general: LangManager.getLang(LangKeys.FEEDBACK_TYPE_GENERAL),
  city_request: LangManager.getLang(LangKeys.FEEDBACK_TYPE_AREA),
  feature_request: LangManager.getLang(LangKeys.FEEDBACK_TYPE_REQUEST),
  criticism: LangManager.getLang(LangKeys.FEEDBACK_TYPE_CRITICISM),
  maps: LangManager.getLang(LangKeys.FEEDBACK_TYPE_MAPS),
  activities: LangManager.getLang(LangKeys.FEEDBACK_TYPE_ACTIVITIES),
  landmarks: LangManager.getLang(LangKeys.FEEDBACK_TYPE_LANDMARKS),
  new_features: LangManager.getLang(LangKeys.FEEDBACK_NEW_FEATURES),
};

export default function FeedbackPopup() {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(isLoggedInSelector);
  const feedbackPopup = useSelector(feedbackPopupSelector);
  const closePopup = () =>
    dispatch(hidePopup({ popupType: PopupTypes.FEEDBACK }));

  const [message, setMessage] = useState(feedbackPopup.message || "");
  const [category, setCategory] = useState(
    feedbackPopup.category || FeedbackCategory.general
  );

  const [
    submitFeedback,
    { data: submitFeedbackResponse, isLoading: isSubmitFeedbackLoading, reset },
  ] = useSubmitFeedbackMutation();
  const [
    submitFeedbackPublic,
    {
      data: submitFeedbackPublicResponse,
      isLoading: isSubmitFeedbackPublicLoading,
      reset: resetPublic,
    },
  ] = useSubmitFeedbackPublicMutation();

  const submit = () => {
    console.log(isLoggedIn);
    if (isLoggedIn) {
      submitFeedback({ message, category });
    } else {
      submitFeedbackPublic({ message, category });
    }
  };

  function renderSending() {
    return <CircularProgress />;
  }

  function renderSent() {
    return (
      <>
        <div>{LangManager.getLang(LangKeys.FEEDBACK_THANKS)}</div>
        <div style={{ flex: 1 }} />
        <div className={styles.buttonContainer}>
          <Button
            variant="contained"
            classes={{ root: styles.button, text: styles.buttonLabel }}
            onClick={close}
          >
            {LangManager.getLang(LangKeys.FEEDBACK_CLOSE)}
          </Button>
        </div>
      </>
    );
  }

  /**
   * When the popup shows / hides. Clear the data. Endpoints and fields
   */
  useEffect(() => {
    // Clear endpoints
    reset();
    resetPublic();

    // Clear fields
    setCategory(feedbackPopup.category || FeedbackCategory.general);
    setMessage(feedbackPopup.message || "");
  }, [feedbackPopup.visible]);

  function renderContent() {
    const sent =
      submitFeedbackResponse?.success || submitFeedbackPublicResponse?.success;

    if (sent) {
      return renderSent();
    }

    return (
      <>
        <textarea
          className={styles.textInput}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          value={message}
        />
        <div className={styles.categoryContainer}>
          <div className={styles.categoryText}>
            {`${LangManager.getLang(LangKeys.FEEDBACK_TYPE)}`}
          </div>
          <Select
            value={category}
            onChange={(e) => {
              setCategory(e.target.value as FeedbackCategory);
            }}
          >
            {Object.keys(categories).map((cat) => {
              return (
                <MenuItem value={cat} key={cat}>
                  {categories[cat]}
                </MenuItem>
              );
            })}
            <MenuItem></MenuItem>
          </Select>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            variant="contained"
            color={"primary"}
            onClick={submit}
            fullWidth
          >
            {LangManager.getLang(LangKeys.FEEDBACK_SUBMIT)}
          </Button>
        </div>
      </>
    );
  }
  const close = () => {
    closePopup();
  };

  const loading = isSubmitFeedbackLoading || isSubmitFeedbackPublicLoading;
  return (
    <Dialog open={feedbackPopup.visible} onClose={close} fullScreen>
      <div className={styles.position} onClick={close}>
        <div
          className={styles.container}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={classNames(sharedStyles.heading3, styles.header)}>
            {LangManager.getLang(LangKeys.FEEDBACK_HEADER)}
          </div>
          <div className={classNames(styles.subHeading, sharedStyles.text)}>
            Write your message here or email{" "}
            <a href={"mailto: matthew@citypainter.io"}>
              matthew@citypainter.io
            </a>
          </div>
          {loading ? renderSending() : renderContent()}
        </div>
      </div>
    </Dialog>
  );
}
