import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import IntroLayout from "../../components/introlayout/IntroLayout";
import { Button, Checkbox, TextField } from "@mui/material";
import styles from "./AccountSetup.module.css";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import WaitForData from "../../components/WaitForData";
import { PopupTypes, showPopup } from "../../state/popups/PopupsActions";
import {
  useAddUserMutation,
  useImportFromVendorMutation,
  useUpdateUserMutation,
  useUserQuery,
} from "../../state/api/ApiSlice";
import { isLoggedInSelector } from "../../state/userslice/UserSlice";
import { isMobile } from "../../platform/PlatformManager";
import DisplayIf from "../../components/display-if/DisplayIf";

const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export default function AccountSetup() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { vendor } = useParams<{ vendor: string }>();

  const [email, setEmail] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const [
    addUser,
    {
      data: addUserResponse,
      isLoading: addUserIsLoading,
      isError: addUserError,
    },
  ] = useAddUserMutation();

  const isLoggedIn = useSelector(isLoggedInSelector);
  const { data: user, isLoading: userIsLoading } = useUserQuery(
    {},
    { skip: !isLoggedIn }
  );
  const [updateUser, { data: updateUserResponse }] = useUpdateUserMutation();
  const [importFromVendor] = useImportFromVendorMutation();

  useEffect(() => {
    const search: string = window.location.search;
    const params = new URLSearchParams(search);
    const oauth_token = params.get("oauth_token") || "";
    const code = params.get("code") || "";
    const scopesString = params.get("scope") || "";
    const scopes = scopesString.split(",");
    if (user?.success && user.user) {
      //  Sometimes users try and refesh the page.
      //  If the user is already authenticated then do not try and reauthenticate
    } else {
      if (vendor === "strava" && !scopes.includes("activity:read")) {
        dispatch(showPopup({ popupType: PopupTypes.VENDOR_PERMISSIONS_ERROR }));
      } else {
        if (!code && !oauth_token) {
          history.goBack();
        } else {
          if (vendor === "garmin") {
            const oauth_verifier = params.get("oauth_verifier") || "";
            addUser({ oauth_token, oauth_verifier, vendor });
          }
          if (vendor === "strava") {
            addUser({ code, vendor, permissions: scopesString });
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && addUserResponse && addUserResponse?.success) {
      const { vendor } = addUserResponse;
      importFromVendor({ vendor_id: vendor });
    }
  }, [addUserResponse, isLoggedIn]);

  useEffect(() => {
    if (updateUserResponse?.success) {
      console.log("user Added");
      history.replace("/account_setup_import");
    }

    // Move to next page if the user already has an email address
    if (!addUserIsLoading && !userIsLoading && user?.success) {
      if (user.user.email) {
        history.replace("/account_setup_import");
      }
    }
  }, [addUserIsLoading, userIsLoading, user]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value || "";
    setEmail(email);
  };

  const submit = () => {
    if (!email.match(emailRegex)) {
      alert("Please enter a valid email");
      return;
    }
    if (!checkbox) {
      alert("Please accept the privacy policy and the terms and conditions");
      return;
    }
    updateUser({ email });
  };
  function renderContent() {
    return (
      <div className={styles.content}>
        <div className={classNames(sharedStyles.heading2, styles.header)}>
          {LangManager.getLang(LangKeys.REGISTER_COMPLETE_HEADER)}
        </div>
        <DisplayIf
          condition={Boolean(addUserResponse && addUserResponse.success)}
        >
          <TextField
            variant="filled"
            color="primary"
            label={LangManager.getLang(LangKeys.REGISTER_EMAIL)}
            InputLabelProps={{ classes: { root: styles.inputLabel } }}
            InputProps={{
              inputMode: "email",
              disableUnderline: true,
            }}
            onChange={handleInput}
            autoFocus={!isMobile()}
          />
        </DisplayIf>

        <DisplayIf condition={Boolean(addUserError)}>
          <div className={classNames(sharedStyles.heading3, styles.error)}>
            There has been an error setting up your account. Please go back and
            try again
          </div>
        </DisplayIf>

        <div className={classNames(sharedStyles.heading5, styles.subheader)}>
          {LangManager.getLang(LangKeys.REGISTER_SUBHEADER)}
        </div>
        <div style={{ flex: 1 }}> </div>
        <div className={styles.checkBox}>
          <Checkbox
            color={"primary"}
            onChange={(e, value) => {
              setCheckbox(value);
            }}
          />
          <div
            className={classNames(sharedStyles.heading5, styles.checkboxLabel)}
          >
            {LangManager.getLang(LangKeys.REGISTER_AGREE)}{" "}
            <Link to={"/privacy_policy"}>
              {LangManager.getLang(LangKeys.REGISTER_PP)}
            </Link>
            {" and "}
            <Link to={"/t_and_c"}>
              {LangManager.getLang(LangKeys.REGISTER_TERMS)}
            </Link>
          </div>
        </div>

        <Button
          variant="contained"
          classes={{ root: styles.button, text: styles.buttonLabel }}
          onClick={submit}
        >
          {LangManager.getLang(LangKeys.REGISTER_CONTINUE)}
        </Button>
      </div>
    );
  }

  return (
    <IntroLayout height={400}>
      <div className={styles.container}>
        <WaitForData isLoading={userIsLoading || addUserIsLoading}>
          {renderContent()}
        </WaitForData>
      </div>
    </IntroLayout>
  );
}
